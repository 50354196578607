import { Modal, ModalBody } from "reactstrap";

import {
  CommonGrid,
  CommonText,
  ExportReportRadioButton,
  CommonFlex,
  ModalCrossIcon, 
  IconButton,
} from "../../styledComponents/common";

import csv from "../../assets/img/icons/csv.svg";
import crossIcon from "../../assets/img/icons/cross_black.svg";


const BackendExportModal = ({
  isOpen,
  toggle,
  excelExport,
  title,
  timeFormat,
  setTimeFormat,
  timeFormatText,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `30vh`, maxWidth: `450px` }}
    >
      <ModalBody style={{ padding: `35px 0 15px 0`, position: "relative" }}>
        <ModalCrossIcon src={crossIcon} alt="" onClick={() => toggle()} />
        <div style={{ padding: `0 30px` }}>
          <CommonText fontSize="18px" fontWeight="600" name="true">
            Export {title} Report
          </CommonText>

          <CommonText style={{ marginTop: `30px` }} fontWeight="600">
            Choose Time Format {timeFormatText && `of '${timeFormatText}'`}
          </CommonText>
          <CommonGrid
            columns="auto auto auto"
            style={{
              width: `max-content`,
              marginTop: "15px",
            }}
          >
            <CommonFlex
              onClick={() => setTimeFormat("1h5m")}
              style={{
                cursor: "pointer",
                gap: "10px",
              }}
            >
              <ExportReportRadioButton active={timeFormat === "1h5m"} />
              1h 5m
            </CommonFlex>
            <CommonFlex
              onClick={() => setTimeFormat(null)}
              style={{
                cursor: "pointer",
                gap: "10px",
              }}
            >
              <ExportReportRadioButton active={timeFormat === null} />
              1:5
            </CommonFlex>
            <CommonFlex
              onClick={() => setTimeFormat("h:m:s")}
              style={{
                cursor: "pointer",
                gap: "10px",
              }}
            >
              <ExportReportRadioButton active={timeFormat === "h:m:s"} />
              01:05:25
            </CommonFlex>
          </CommonGrid>
          <CommonText style={{ marginTop: `30px` }} fontWeight="600">
            Choose Format
          </CommonText>
          <CommonGrid columns="1fr 1fr" padding="20px 0 25px 0">
            <IconButton onClick={() => excelExport && excelExport()}>
              <div>
                <img src={csv} alt="excel" width="28px" padding="12px 25px" />
              </div>
              <div>CSV</div>
            </IconButton>
          </CommonGrid>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BackendExportModal;
