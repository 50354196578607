import { Modal, ModalBody } from "reactstrap";
import { ModalButtonSection } from "../../../styledComponents/members";
import { CommonText } from "../../../styledComponents/common";
import { WhiteButton, ColoredButton } from "../../../styledComponents/buttons";

import React from "react";

const ChangeClientModal = ({
  isOpen,
  toggle,
  handleChange,
  oldClient,
  newClient,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          {newClient?.label ? (
            <>
              The project was assigned to{" "}
              <span
                style={{
                  fontWeight: `bold`,
                }}
              >
                {oldClient?.label}
              </span>
              . Are you sure you want to re-assign to{" "}
              <span
                style={{
                  fontWeight: `bold`,
                }}
              >
                {newClient?.label}
              </span>
              ?
            </>
          ) : (
            <>
              Do you want to remove
              <span
                style={{
                  fontWeight: `bold`,
                }}
              >
                {oldClient?.label}
              </span>
              from the project?
            </>
          )}
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          {
            // newClient?.label ? (
            <ColoredButton
              type="delete"
              padding={"0 30px"}
              onClick={() => handleChange()}
            >
              Change
            </ColoredButton>
            // ) : (
            //   <ColoredButton
            //     type="delete"
            //     padding={"0 30px"}
            //     onClick={() => handleChange()}
            //   >
            //     Remove
            //   </ColoredButton>
            // )
          }
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default ChangeClientModal;
