import styled from "styled-components";

export const TabLeftGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: space-between;
  grid-column-gap: 10%;
  @media (max-width: 800px) {
    grid-column-gap: 10px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    justify-content: start;
    grid-row-gap: 15px;
  }
`;

export const ClientCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 12px;
  margin-top: 20px;
`;
export const ClientCard = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.button};
`;
export const ClientCardInnerContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `40px auto`};
  grid-column-gap: 12px;
  padding: ${({ padding }) => padding || "15px"};
  align-items: center;
  cursor: pointer;
`;
export const InvoicedCapsule = styled.div`
  padding: 5px 13px;
  border-radius: 14px;
  color: ${({ invoiced, theme }) => (invoiced ? `#82C4BB` : `#EFBD1A`)};
  background-color: ${({ invoiced, theme }) =>
    invoiced ? theme.colors.main.primaryLight : `#FBF3DA`};
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  width: ${({ width }) => width};
`;

export const HoveredSection = styled.div`
  position: absolute;
  top: ${({ top }) => top || "-15px"};
  right: ${({ right }) => right || "15px"};
  display: grid;
  grid-template-columns: ${({ columns, unarchive }) =>
    columns ? columns : unarchive ? `1fr 1fr` : `1fr 1fr 1fr`};
  grid-gap: 10px;
`;

export const RoundHover = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-content: center;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  padding: 2px;
  background-color: white;
  width: 25px;
  height: 25px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.secondary};
  }
`;

export const ToolTipContainer = styled.div`
  position: absolute;
  top: -33px;
  left: 3px;
  width: 64px;
`;
export const InnerDivTooltip = styled.div`
  position: relative;
`;

export const TooltipText = styled.div`
  position: absolute;
  top: 3px;
  left: 0px;
  font-size: 12px;
  color: rgba(47, 57, 78, 0.7);
  width: 64px;
  text-align: center;
`;

// ---- ADD CLIENT -----

export const AddClientContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 7%;
  max-width: 760px;
  justify-content: space-between;
  margin-top: 25px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const ButtonSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  padding: 20px 0;
  grid-row: 4 / 4;
  @media (max-width: 767px) {
    grid-row: auto;
  }
  @media (max-width: 374px) {
    grid-template-columns: 180px;
    grid-gap: 20px;
    justify-content: center;
  }
`;

// ----- CLIENT DETAIL -----

export const SubtitleSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  justify-content: space-between;
`;

export const ClientNameRow = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 50px;
  justify-content: start;
  margin: 15px 0 30px;
`;

export const CreatedTextgrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 15px;
  justify-content: start;
`;

export const GeneralInfoSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-column-gap: 20px;
  margin: 15px 0 30px;
  max-width: 1200px;
`;

export const GeneralInfoItem = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 10px;
  justify-content: center;
  align-content: center;
  padding: 15px 20px;
  border-radius: 6px;
  text-align: center;
  background-color: ${({ backColor }) =>
    backColor === 1
      ? `#EAF9F7`
      : backColor === 2
      ? `#FBF3DA`
      : backColor === 3
      ? `#DFE2FF`
      : backColor === 4
      ? `#D0F6FF`
      : backColor === 5
      ? `#F9D8D6`
      : backColor === 6
      ? `#c7f2d0`
      : backColor === 7
      ? `#EFF9DA`
      : backColor === 8
      ? `#FEF5E8`
      : `#ECF0FC`};
  min-height: ${(minHeight) => minHeight || "100px"};
  width: ${({ width }) => width || "auto"}; /* Add width */
  margin: ${({ margin }) => margin || "0"}; /* Conditional margin */
`;

export const InvoiceTitle = styled.p`
  margin: 45px 0 10px;
  color: #2f394e;
  font-size: 16px;
  font-weight: 600;
`;

export const InvoiceTableSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1.4fr minmax(100px, 150px) 1fr;
  grid-column-gap: 20px;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: ${({ heading, theme }) =>
    heading && `1px solid ${theme.colors.border.secondary}`};
  :nth-of-type(odd) {
    background-color: ${({ heading }) => !heading && `#f8f9fc`};
  }
  /* @media (max-width: 1250px) {
    grid-template-columns: 160px 110px 150px 200px 150px 100px 150px;
    grid-column-gap: 10px;
  } */
`;
export const TableItem = styled.div`
  display: grid;
  padding: ${(props) => (props.buttonPadding ? `8px 0` : `15px 0`)};
  color: ${({ theme }) => theme.colors.textMain};
  align-content: center;
  ${({ hoverPointer }) =>
    hoverPointer &&
    css`
      cursor: pointer;
    `}
`;
export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  button {
    background-color: #007bff; /* Primary blue color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #0056b3; /* Darker blue on hover */
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Focus ring */
    }

    &:disabled {
      background-color: #d6d6d6; /* Disabled gray */
      color: #a1a1a1;
      cursor: not-allowed;
    }
  }
`;
export const ClientTableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr minmax(120px, 200px) minmax(100px, 180px) 150px;
  grid-column-gap: 20px;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: ${({ heading, theme }) =>
    heading && `1px solid ${theme.colors.border.secondary}`};
  :nth-of-type(even) {
    background-color: ${({ heading }) => !heading && `#f8f9fc`};
  }
`;
export const StatusBox = styled.div`
  font-size: 13.5px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  color: ${({ theme }) => theme.colors.main.white};
  padding: 5px 10px;
  margin-right: auto;
  text-align: center;
  text-transform: capitalize;
  min-width: 120px;
  background-color: ${({ status, theme }) =>
    status === "draft"
      ? theme.colors.main.grayMedium
      : status === "sent"
      ? theme.colors.main.blue
      : status === "partial"
      ? theme.colors.main.warning
      : theme.colors.main.primary};
`;
