import { useState, useEffect } from "react";
import GeneralDetails from "./GeneralDetails";
import AddPeoplePermissions from "./AddPeoplePermissions";
import AddBiilingHours from "./AddBillingsHours";
import AddTasks from "./AddTasksNew";
import {
  SaveAndProceedContainer,
  SaveAndProceedButton,
} from "../../../styledComponents/createProject";
import {
  Container,
  PageTitle,
  ContentHeader,
} from "../../../styledComponents/common";
import { PrimaryButton } from "../../../styledComponents/buttons";

import queryString from "query-string";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import AccessDeniedComponent from "../../../components/AccessDenied/AccessDenied";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import { createNotification } from "../../../modules/notificationManager";

const EditProject = ({
  isLoading,
  location,
  history,

  projectAssigneeIsLoading,
  selectedOrganization,
  clearProjectAccessDenied,
  getProjectDetails,
  getProjectAssigneeList,
  projectAssigneeList,
  getProjectTaskList,
  projectTaskList,
  projectTaskListInProject,
  projectAddTask,
  projectEditTask,
  projectDeleteTask,
  addTaskAssignee,
  deleteTaskAssignee,
  taskListLoading,
  taskIsLoading,
  loadingTaskId,
  taskAssigneeIsLoading,
  loadingTaskAssigneeId,

  // pagination
  projectTaskCount,
  projectTaskPageSize,

  accessDenied,
  updateProjectIsLoading,
  updateProject,
  projectDetails,
  getBudgetLoading,
  getBillingLoading,
  saveBudgetLoading,
  saveBillingLoading,
  addProjectBilling,
  updateProjectBilling,
  clearProjectBilling,
  addProjectBudget,
  updateProjectBudget,
  clearProjectBudget,
  setPaymentConfiguration,
  getProjectBilling,
  projectBilling,
  getProjectBudget,
  projectBudget,
  loadingAssigneeId,
  projectTeamIsLoading,
  loadingTeamId,
  addProjectAssignee,
  updateProjectAssignee,
  projectAssigneeUpdated,
  updatedAssigneeData,
  deleteProjectAssignee,
  addTeamToProject,
  getClientList,
  clientList,
  generalDetailsUpdated,
  getOrganizationMembersList,
  orgMembersList,
  getTeamList,
  teamList,
  clearEditedProject,
}) => {
  const [projectId, setProjectId] = useState(null);
  const [generalDetailsActive, setGeneralDetailsActive] = useState(true);
  const [addPeopleActive, setAddPeopleActive] = useState(false);
  const [billingsActive, setBillingsActive] = useState(false);
  const [addTasksActive, setAddTasksActive] = useState(false);

  const [currency, setCurrency] = useState("");

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    return () => {
      clearEditedProject();
      clearProjectAccessDenied();
    };
  }, []);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.currency
    ) {
      setCurrency(selectedOrganization.configuration.currency);
    }
  }, [selectedOrganization]);

  const toggleGeneralDetails = () => {
    setGeneralDetailsActive(!generalDetailsActive);
    setAddPeopleActive(false);
    setBillingsActive(false);
    setAddTasksActive(false);
  };
  const toggleAddPeople = () => {
    setAddPeopleActive(!addPeopleActive);
    // setGeneralDetailsActive(false);
    setBillingsActive(false);
    setAddTasksActive(false);
  };
  const toggleBillings = () => {
    setBillingsActive(!billingsActive);
    // setGeneralDetailsActive(false);
    setAddPeopleActive(false);
    setAddTasksActive(false);
  };
  const toggleAddTasks = () => {
    setAddTasksActive(!addTasksActive);
    // setGeneralDetailsActive(false);
    setAddPeopleActive(false);
    setBillingsActive(false);
  };

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (location.search) {
        const queryParsed = queryString.parse(location.search);
        if (queryParsed.id) {
          setProjectId(queryParsed.id);
          let payload = {
            organization_id: selectedOrganization.id,
            project_id: queryParsed.id,
          };
          getProjectDetails(payload);
        }
      }
      let payload = {
        organization_id: selectedOrganization.id,
      };
      getOrganizationMembersList(payload);
      getTeamList(payload);
    }
  }, [selectedOrganization]);

  // close general details when project is saved
  useEffect(() => {
    if (generalDetailsUpdated) {
      setGeneralDetailsActive(false);
    }
  }, [generalDetailsUpdated]);
  useEffect(() => {
    if (!isLoading) {
      if (
        projectDetails === null &&
        !firstLoad &&
        (!accessDenied || selectedOrganization.role === "member")
      ) {
        createNotification("error", "Access Denied!");
        history.push("/user/projects");
      }
      if (firstLoad) {
        setFirstLoad(false);
      }
    }
  }, [isLoading]);
  return (
    <div className="content">
      <PageTitle>Projects</PageTitle>
      <ContentHeader margin="30px 0 0 0">
        <BackButtonComponent
          onClick={() => history.push("/user/projects")}
          subTitle="Back to all projects"
        />
      </ContentHeader>
      {accessDenied ? (
        <Container padding="20px 0">
          <AccessDeniedComponent history={history} />
        </Container>
      ) : (
        <Container padding="20px 0">
          {isLoading || (!projectDetails && !accessDenied) ? (
            <ComponentCircleLoader />
          ) : !projectDetails?.managed ? (
            <AccessDeniedComponent history={history} />
          ) : (
            <>
              <GeneralDetails
                updateProjectIsLoading={updateProjectIsLoading}
                projectDetails={projectDetails}
                updateProject={updateProject}
                selectedOrganization={selectedOrganization}
                getClientList={getClientList}
                clientList={clientList}
                history={history}
                generalDetailsActive={generalDetailsActive}
                toggleGeneralDetails={toggleGeneralDetails}
                toggleAddPeople={toggleAddPeople}
                generalDetailsUpdated={generalDetailsUpdated}
              />
              <AddPeoplePermissions
                getMembersList={getOrganizationMembersList}
                membersList={orgMembersList}
                getTeamList={getTeamList}
                teamList={teamList}
                projectId={projectId}
                selectedOrganization={selectedOrganization}
                getProjectAssigneeList={getProjectAssigneeList}
                projectAssigneeList={projectAssigneeList}
                addProjectAssignee={addProjectAssignee}
                updateProjectAssignee={updateProjectAssignee}
                projectAssigneeUpdated={projectAssigneeUpdated}
                updatedAssigneeData={updatedAssigneeData}
                deleteProjectAssignee={deleteProjectAssignee}
                addTeamToProject={addTeamToProject}
                addPeopleActive={addPeopleActive}
                toggleAddPeople={toggleAddPeople}
                projectAssigneeIsLoading={projectAssigneeIsLoading}
                loadingAssigneeId={loadingAssigneeId}
                projectTeamIsLoading={projectTeamIsLoading}
                loadingTeamId={loadingTeamId}
              />
              <AddBiilingHours
                getProjectAssigneeList={getProjectAssigneeList}
                projectAssigneeList={projectAssigneeList}
                selectedOrganization={selectedOrganization}
                currency={currency}
                getProjectBilling={getProjectBilling}
                projectBilling={projectBilling}
                getProjectBudget={getProjectBudget}
                projectBudget={projectBudget}
                projectId={projectId}
                addProjectBilling={addProjectBilling}
                updateProjectBilling={updateProjectBilling}
                clearProjectBilling={clearProjectBilling}
                addProjectBudget={addProjectBudget}
                updateProjectBudget={updateProjectBudget}
                clearProjectBudget={clearProjectBudget}
                setPaymentConfiguration={setPaymentConfiguration}
                billingsActive={billingsActive}
                setBillingsActive={setBillingsActive}
                toggleBillings={toggleBillings}
                toggleAddTasks={toggleAddTasks}
                getBudgetLoading={getBudgetLoading}
                getBillingLoading={getBillingLoading}
                saveBudgetLoading={saveBudgetLoading}
                saveBillingLoading={saveBillingLoading}
              />
              <AddTasks
                selectedOrganization={selectedOrganization}
                projectId={projectId}
                projectName={projectDetails && projectDetails.name}
                getProjectTaskList={getProjectTaskList}
                projectTaskList={projectTaskList}
                projectTaskListInProject={projectTaskListInProject}
                getProjectAssigneeList={getProjectAssigneeList}
                projectAssigneeList={projectAssigneeList}
                projectAssigneeIsLoading={projectAssigneeIsLoading}
                addTask={projectAddTask}
                editTask={projectEditTask}
                deleteTask={projectDeleteTask}
                addTaskAssignee={addTaskAssignee}
                deleteTaskAssignee={deleteTaskAssignee}
                addTasksActive={addTasksActive}
                toggleAddTasks={toggleAddTasks}
                taskListLoading={taskListLoading}
                taskIsLoading={taskIsLoading}
                loadingTaskId={loadingTaskId}
                taskAssigneeIsLoading={taskAssigneeIsLoading}
                loadingTaskAssigneeId={loadingTaskAssigneeId}
                // pagination
                taskCount={projectTaskCount}
                taskPageSize={projectTaskPageSize}
              />
              <SaveAndProceedContainer>
                <PrimaryButton
                  padding="0 25px"
                  onClick={() => history.push("/user/projects")}
                >
                  Save & Proceed
                </PrimaryButton>
              </SaveAndProceedContainer>
            </>
          )}
        </Container>
      )}
    </div>
  );
};

export default EditProject;
