import { useState, useRef, useEffect } from "react";

import {
  AssignListContainer,
  AssignListItem,
  AssignListText,
} from "../../styledComponents/common";
import {
  WhiteButtonWithIcon,
  ButtonIcon,
} from "../../styledComponents/buttons";

import DropdownLoader from "../DropdownLoader/DropdownLoader";
import FirstRoundLetter from "../ColorLetterRound/FirstRoundLetter";
import FirstRoundLetterComp from "../FirstRoundLetter/FirstRoundLetter";
import { useLocation } from "react-router-dom";

import PlusBlack from "../../assets/img/icons/plus_black.svg";

const AddProjectDropdown = ({
  isLoading,
  selectedOrganization,
  assignedProjects,
  index,
  updateState,
  projectsList,
  getProjectList,
  buttonPadding,
  buttonText,
  refetchProjects,
}) => {
  const [addProject, setAddProject] = useState(false);
  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);
  const [addList, setAddList] = useState([]);

  const location = useLocation();
  const isClientDetailsRoute = location.pathname.includes("client-details");
  useEffect(() => {
    if (projectsList && projectsList.length > 0) {
      let newArr = [...projectsList];
      if (assignedProjects && assignedProjects.length > 0) {
        assignedProjects.map((project) => {
          newArr = newArr.filter((item) => item.id !== project.id);
        });
      }
      setAddList(newArr);
    } else if (projectsList && projectsList.length === 0) {
      setAddList([]);
    }
  }, [projectsList, assignedProjects]);

  //function
  useOutsideClickHandler(wrapperRef1, wrapperRef2);

  const showAssignee = () => {
    setAddProject(true);
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
        unassigned: isClientDetailsRoute,
      };
      getProjectList(payload);
    }
  };
  useEffect(() => {
    if (refetchProjects && selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
        unassigned: isClientDetailsRoute,
      };
      getProjectList(payload);
    }
  }, [refetchProjects]);

  function useOutsideClickHandler(ref1, ref2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref1.current &&
          !ref1.current.contains(event.target) &&
          ref2.current &&
          !ref2.current.contains(event.target)
        ) {
          setAddProject(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2]);
  }
  return (
    <div style={{ position: `relative` }}>
      <WhiteButtonWithIcon
        padding={
          buttonPadding && buttonPadding !== "" ? buttonPadding : `0 15px`
        }
        hoverBorderColor="#20bead"
        onClick={() => {
          showAssignee();
        }}
        ref={wrapperRef2}
      >
        <ButtonIcon src={PlusBlack} alt="" size="15px" />
        {buttonText && buttonText !== "" ? buttonText : `Assign a project`}
      </WhiteButtonWithIcon>

      {addProject && (
        <AssignListContainer ref={wrapperRef1} padding="5px 0">
          {isLoading ? (
            <DropdownLoader loading={isLoading} />
          ) : (
            <>
              {" "}
              {addList && addList.length > 0 ? (
                addList.map((project, i) => (
                  <AssignListItem
                    key={i}
                    onClick={() => {
                      updateState(project, index);
                      setAddProject(false);
                    }}
                    columns="25px auto"
                  >
                    <FirstRoundLetterComp
                      text={project.name}
                      backColor={i}
                      size="25px"
                      fontSize="11px"
                      color={project.color || null}
                    />
                    <AssignListText>{project.name}</AssignListText>
                  </AssignListItem>
                ))
              ) : (
                <AssignListText style={{ padding: "10px 0 10px 15px" }}>
                  No projects found.
                </AssignListText>
              )}
            </>
          )}
        </AssignListContainer>
      )}
    </div>
  );
};

export default AddProjectDropdown;
