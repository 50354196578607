import styled from "styled-components";
import moment from "moment-timezone";
import { getRandomColor } from "../../../utils/getRandomColor";

const TimeDiv = styled.div`
  height: 30px;
  width: 90px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2005;
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ backgroundColor }) => backgroundColor};
  border: ${({ isActive, theme }) => (isActive ? `2px solid ${theme.colors.main.primary}` : "none")};
  position: relative;
  /* @media screen and (max-width: 1600px) {
    max-height: 28px;
    font-size: 12.5px;
  } */
`;

const TimeBlankDiv = styled.div`
  height: 30px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ theme }) => theme.colors.main.white};
  border: ${({ isActive, theme }) => (isActive ? `2px solid ${theme.colors.main.primary}` : "none")};
  border-radius: ${({ theme }) => theme.borderRadii.button};
`;

const TimeSpan = ({
  backgroundColor,
  event,
  selectedMember,
  getTimesheetDetails,
  handleSetUserData,
  setSelectedPanelDate,
  activeDate,
  detailsPanelIsOpen,
}) => {
  return (
    <>
      {event.title === "0h 00m" ? (
        <TimeBlankDiv
          isActive={activeDate===moment(event.event.start).format("YYYY-MM-DD") && detailsPanelIsOpen}
        >-</TimeBlankDiv>
      ) : (
        <TimeDiv
          backgroundColor={backgroundColor}
          onClick={() => {
            getTimesheetDetails(
              moment(event.event && event.event.start),
              selectedMember
                ? selectedMember.id
                : localStorage.getItem("user_id")
            );
            handleSetUserData(
              moment(event.event && event.event.start),
              event.title
            );
            setSelectedPanelDate(moment(event.event.start).format("YYYY-MM-DD"));
          }}
          isActive={activeDate===moment(event.event.start).format("YYYY-MM-DD") && detailsPanelIsOpen}
        >
          {event.title}
        </TimeDiv>
      )}
    </>
  );
};

export default TimeSpan;
