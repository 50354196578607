import { useState, useEffect } from "react";

import queryString from "query-string";

import AddProjectDropdown from "../../../components/CustomDropdown/AddProjectDropdown";
import CrossIcon from "../../../assets/img/icons/cross_black.svg";

import { AssigneesManageImgTextGrid } from "../../../styledComponents/createProject";
import { StatusBox } from "../../../styledComponents/invoice";
import {
  PaddingBox,
  UpperTitleButtonSection,
  ButtonFlexContainer,
} from "../../../styledComponents/members";
import {
  PageTitle,
  CommonText,
  TableText,
  Container,
  CardTitle,
  FirstWordRound,
  ContentHeader,
} from "../../../styledComponents/common";
import {
  ColoredButtonWithIcon,
  WhiteButtonWithIcon,
  ButtonIcon,
  PrimaryButton,
  WhiteButton,
} from "../../../styledComponents/buttons";
import {
  ClientNameRow,
  GeneralInfoSection,
  GeneralInfoItem,
  InvoiceTitle,
  InvoiceTableSection,
  HoveredSection,
  RoundHover,
} from "../../../styledComponents/clients";
import { TableItem } from "../../../styledComponents/teams";

import { currencySymbolMap } from "../../../utils/currencies";

import DeleteClientModal from "../DeleteClientModal";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import Plus from "../../../assets/img/icons/plus_white.svg";
import Bean from "../../../assets/img/icons/delete.svg";
import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";
import CommonTooltip from "../../../components/Tooltip/CommonTooltip";

const ClientInvoiceSection = ({ invoicesList = [], history }) => {
  return (
    <>
      <InvoiceTableSection heading>
        <TableItem>
          <TableText>Invoice Number</TableText>
        </TableItem>
        <TableItem>
          <TableText>Issued Date</TableText>
        </TableItem>
        <TableItem>
          <TableText>Paid Amount / Total Amount</TableText>
        </TableItem>
        <TableItem>
          <TableText>Status</TableText>
        </TableItem>
      </InvoiceTableSection>
      {invoicesList.map((invoice, index) => (
        <InvoiceTableSection key={invoice.id}>
          <TableItem>
            <TableText name>{invoice.invoice_number}</TableText>
          </TableItem>
          <TableItem>
            <TableText name>{invoice.issued_date}</TableText>
          </TableItem>
          <TableItem>
            <TableText name>
              {`${
                currencySymbolMap[invoice.currency]
              } ${invoice.paid_amount.toFixed(2)} / ${
                currencySymbolMap[invoice.currency]
              } ${invoice.discounted_amount.toFixed(2)}`}
            </TableText>
          </TableItem>
          <TableItem>
            <StatusBox status={invoice.status}>
              {invoice.status === "partial" ? "Partially Paid" : invoice.status}
            </StatusBox>
          </TableItem>
          <TableItem>
            <PrimaryButton
              onClick={() =>
                history.push(`/user/invoice-preview?id=${invoice.id}`)
              }
            >
              Preview Invoice
            </PrimaryButton>
          </TableItem>
        </InvoiceTableSection>
      ))}
    </>
  );
};

const ClientDetails = (props) => {
  const [clientDetails, setClientDetails] = useState(null);
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState(-1);
  const [projectIndex, setProjectIndex] = useState(null);
  const onProjectHover = (index) => {
    setProjectIndex(index);
  };
  const tooltipChange = (index) => {
    setTooltip(index);
  };

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      if (props.location.search) {
        const queryParsed = queryString.parse(props.location.search);
        let payload = {
          organization_id: props.selectedOrganization.id,
          client_id: queryParsed.id,
        };
        props.getClientDetails(payload);
      }
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.clientDetails && props.clientDetails !== null) {
      setClientDetails(props.clientDetails);
    } else if (!props.clientDetails) {
      setClientDetails(null);
    }
  }, [props.clientDetails]);

  const toggle = () => setModal(!modal);

  const updateProjectList = (project, index) => {
    let details = { ...clientDetails };
    details.projects = [...details.projects, project];
    setClientDetails(details);
  };

  const handleAssignProject = (project) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      project &&
      project.id &&
      clientDetails &&
      clientDetails.id
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        project_id: project.id,
        client_id: clientDetails.id,
        clientDetails: true,
      };
      props.updateProject(payload);
    }
  };

  const handleRemoveProject = (projectId) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      projectId &&
      clientDetails &&
      clientDetails.id
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        project_id: projectId,
        client_id: clientDetails.id,
        clientDetails: true,
      };
      props.removeClientFromProject(payload);
    }
  };

  const handleClientDelete = (clientId) => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        client_id: clientId,
      };
      props.deleteClient(payload);
      setTimeout(() => {
        setModal(!modal);
        props.history.push({
          pathname: "/user/clients",
          state: { from: "details" },
        });
      }, 500);
    }
  };

  return (
    <div className="content">
      <PageTitle>Clients</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => props.history.push("/user/clients")}
          subTitle="Back to all clients"
        />
        <ColoredButtonWithIcon
          onClick={() => props.history.push("/user/add-client")}
        >
          <ButtonIcon src={Plus} alt="" />
          Add Client
        </ColoredButtonWithIcon>
      </ContentHeader>
      <Container style={{ padding: `30px 0` }}>
        {props.isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <PaddingBox>
              {clientDetails && clientDetails !== null && (
                <>
                  <UpperTitleButtonSection>
                    <CardTitle>Client Details</CardTitle>
                    <UpperTitleButtonSection gap="0">
                      <WhiteButtonWithIcon
                        type="delete"
                        fontSize="14px"
                        onClick={toggle}
                      >
                        <ButtonIcon src={Bean} alt="" />
                        Delete Client
                      </WhiteButtonWithIcon>
                      <DeleteClientModal
                        id={clientDetails.id}
                        isOpen={modal}
                        toggle={toggle}
                        handleDelete={handleClientDelete}
                        isLoading={props.clientDeleteLoading}
                      />
                    </UpperTitleButtonSection>
                  </UpperTitleButtonSection>

                  <ClientNameRow>
                    <AssigneesManageImgTextGrid>
                      {/* <FirstWordRound
                        size="44px"
                        fontSize="14px"
                        backColor="#789CFF"
                      >{`${clientDetails.name
                        .split(" ")[0]
                        .slice(0, 1)
                        .toUpperCase()}${
                        clientDetails.name.split(" ")[1]
                          ? clientDetails.name
                              .split(" ")[1]
                              .slice(0, 1)
                              .toUpperCase()
                          : ``
                      }`}</FirstWordRound> */}
                      <FirstRoundLetterComp
                        text={clientDetails.name}
                        backColor={0}
                        color={clientDetails.color || null}
                      />
                      <CommonText>{clientDetails.name}</CommonText>
                    </AssigneesManageImgTextGrid>
                  </ClientNameRow>

                  <CommonText $label>General Information</CommonText>

                  <GeneralInfoSection>
                    <GeneralInfoItem backColor={1}>
                      <CommonText title>
                        {clientDetails.phone_number
                          ? clientDetails.phone_number
                          : "Phone number not set"}
                      </CommonText>
                      <CommonText $label fontWeight="500">
                        Phone Number
                      </CommonText>
                    </GeneralInfoItem>
                    <GeneralInfoItem backColor={2}>
                      <CommonText title>
                        {clientDetails.email
                          ? clientDetails.email
                          : "Email not set"}
                      </CommonText>
                      <CommonText $label fontWeight="500">
                        Email Address
                      </CommonText>
                    </GeneralInfoItem>
                    <GeneralInfoItem>
                      <CommonText title>
                        {clientDetails.office_location
                          ? clientDetails.office_location
                          : "Address not set"}
                      </CommonText>
                      <CommonText $label fontWeight="500">
                        Office Location
                      </CommonText>
                    </GeneralInfoItem>
                  </GeneralInfoSection>

                  <CommonText $label margin="">
                    Assigned Projects
                  </CommonText>
                  <ButtonFlexContainer>
                    {clientDetails.projects &&
                      clientDetails.projects.length > 0 &&
                      clientDetails.projects.map((project, index) => (
                        <WhiteButton
                          type="cancel"
                          key={index}
                          style={{ position: "relative" }}
                          onMouseEnter={() => onProjectHover(project.id)}
                          onMouseLeave={() => onProjectHover(null)}
                          onClick={() =>
                            tooltip !== project.id &&
                            props.history.push(
                              `/user/project-details?id=${project.id}&type=${
                                project.is_active ? "active" : "archived"
                              }`
                            )
                          }
                        >
                          {project.name}
                          {projectIndex === project.id &&
                            props.selectedOrganization &&
                            props.selectedOrganization.role !== "member" && (
                              <HoveredSection
                                style={{
                                  gridTemplateColumns: "1fr",
                                  right: "0px",
                                  zIndex: 10,
                                }}
                              >
                                <RoundHover
                                  onMouseEnter={() => tooltipChange(project.id)}
                                  onMouseLeave={() => tooltipChange(null)}
                                  onClick={() =>
                                    handleRemoveProject(project.id)
                                  }
                                >
                                  <img src={CrossIcon} alt="cross" />
                                  {tooltip === project.id && (
                                    <CommonTooltip
                                      tooltipText="Unassign Project"
                                      left="-58px"
                                      top="-40px"
                                      width="140px"
                                    />
                                  )}
                                </RoundHover>
                              </HoveredSection>
                            )}
                        </WhiteButton>
                      ))}
                    <AddProjectDropdown
                      isLoading={props.projectListIsLoading}
                      selectedOrganization={props.selectedOrganization}
                      assignedProjects={clientDetails.projects}
                      getProjectList={props.getProjectShortList}
                      updateState={handleAssignProject}
                      projectsList={props.projectList}
                      buttonPadding="9px 15px"
                    />
                  </ButtonFlexContainer>
                  {clientDetails &&
                    clientDetails.invoices &&
                    clientDetails.invoices.length > 0 && (
                      <InvoiceTitle>
                        Previous Invoices for this client
                      </InvoiceTitle>
                    )}
                </>
              )}
            </PaddingBox>
            {clientDetails &&
              clientDetails.invoices &&
              clientDetails.invoices.length > 0 && (
                <ClientInvoiceSection
                  invoicesList={clientDetails.invoices}
                  history={props.history}
                />
              )}
          </>
        )}
      </Container>
    </div>
  );
};

export default ClientDetails;
