import { connect } from "react-redux";

// Component
import ClientDetails from "./ClientDetails";

// Actions
import {
  getClientDetails,
  updateClient,
  deleteClient,
} from "../clientsActions";
import {
  getProjectShortList,
  removeClientFromProject,
  updateProject,
} from "../../Projects/projectsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  isLoading: state.client.isLoading,
  projectListIsLoading: state.project.isLoading,
  clientDetails: state.client.clientDetails,
  projectList: state.project.projectShortList,
  clientDeleteLoading: state.client.clientDeleteLoading,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getClientDetails: (details) => dispatch(getClientDetails({ ...details })),
  updateClient: (details) => dispatch(updateClient({ ...details })),
  deleteClient: (details) => dispatch(deleteClient({ ...details })),
  getProjectShortList: (details) =>
    dispatch(getProjectShortList({ ...details })),
  updateProject: (details) => dispatch(updateProject({ ...details })),
  removeClientFromProject: (details) =>
    dispatch(removeClientFromProject({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
